import React, { useState, useEffect, useContext } from 'react'
import './styles/Home.css'
import KidsStudying from '../images/kids-studying.png'
import GraduateFriends from '../images/graduate-friends.png'
import Children from '../images/poza6.png'
import Learning from '../images/learning.png'
import AllKids from '../images/all-kids.png'
import SchoolGirls from '../images/school-girls.png'
import SchoolBoys from '../images/school-boys.png'
import Levels from '../components/Levels'
import Faq from '../components/Faq'
import AceLogo from '../images/ace-logo.png';
import CeeLogo from '../images/cee-logo.png'
import KingOfKingsLogo from '../images/kingofkings-logo.png'
// import Classroom from '../images/slider2.png'
import Poza2 from '../images/poza2.png'
// import Poza15 from '../images/poza15.png'
import Trip from '../images/trip.png'
import Boys from '../images/boys.png'
import Abc from '../images/slider3.png'
import { GlobalContext } from '../GlobalState'
import { Link } from 'react-router-dom'

const Home = () => {
  let [slide, setSlide] = useState(1);
  const { changePage, checkLan } = useContext(GlobalContext)

  useEffect(() => {
    changePage('home');
  }, [changePage]);

  return (
    <>
      <section className="landing container-fluid">
        <div className="row align-items-center">
          <div className="text col-md-5">
            <h1>{checkLan('O nouă metodă educațională', 'A unique educational system')}<br />{checkLan('în Iași', 'in Iași')}</h1>
            <p className='font-weight-bold'>{checkLan('Centrul Educațional Paideusis aduce în atenția părinților beneficiile unei metode educaționale unice în Iași.', 'Paideusis International Centre of Education brings to the attention of parents the benefits of an exclusive educational method in Iasi.')}</p>
            <Link to='/despre-noi#advantages' className="btn">{checkLan('Beneficii', 'Benefits')}</Link>
          </div>
          <div className="slider col-md-7">
            <i style={{ opacity: slide === 1 ? 0.5 : 1 }} className="fas fa-chevron-left" onClick={() => slide > 1 && setSlide(slide -= 1)}></i>
            <div className="slider-container">
              <div className="images">
                <img style={{ opacity: slide === 1 ? 1 : 0 }} src={Children} alt="children" />
                <img style={{ opacity: slide === 2 ? 1 : 0 }} src={KidsStudying} alt="children" />
                <img style={{ opacity: slide === 3 ? 1 : 0 }} src={Abc} alt="children" />
                <img style={{ opacity: slide === 4 ? 1 : 0 }} src={AllKids} alt="children" />
                <img style={{ opacity: slide === 5 ? 1 : 0 }} src={Trip} alt="children" />
                <img style={{ opacity: slide === 6 ? 1 : 0 }} src={Boys} alt="children" />
                <img style={{ opacity: slide === 7 ? 1 : 0 }} src={SchoolGirls} alt='children' /> 
                <img style={{ opacity: slide === 8 ? 1 : 0 }} src={SchoolBoys} alt='children' /> 
                <img style={{ opacity: slide === 9 ? 1 : 0 }} src={GraduateFriends} alt='children' /> 
              </div>
              {/* <div className="buttons">
                <button className={slide === 1 ? "fas fa-circle" : "far fa-circle"} onClick={() => setSlide(1)}></button>
                <button className={slide === 2 ? "fas fa-circle" : "far fa-circle"} onClick={() => setSlide(2)}></button>
                <button className={slide === 3 ? "fas fa-circle" : "far fa-circle"} onClick={() => setSlide(3)}></button>
              </div> */}
            </div>
            <i style={{ opacity: slide === 9 ? 0.5 : 1 }} className="fas fa-chevron-right" onClick={() => slide < 9 && setSlide(slide += 1)}></i>
          </div>
        </div>
      </section>

      <section className='values d-flex'>
        <div className="value value1">
          <h2>{checkLan('Educație creștină', 'Christian Education')}</h2>
        </div>
        <div className="value value2">
          <h2>{checkLan('Învățare', 'Individualised')}<br />{checkLan('individuală', 'Learning')}</h2>
        </div>
        <div className="value value3">
          <h2>{checkLan('Formarea', 'Character')}<br />{checkLan('caracterului', 'Building')}</h2>
        </div>
        <div className="value value4">
          <h2>{checkLan('Standard', 'High')}<br />{checkLan('academic', 'Academic')}<br />{checkLan('ridicat', 'Standard')}</h2>
        </div>
      </section>

      <div style={{ background: '#f5f3e4', paddingBottom: 'calc(1vw + 1rem)', marginTop: 'calc(5vw + 5rem)' }}>
        <section className="learning container-fluid">
          <div className="row align-items-center">
            <div className="text col-md-7 col-sm-12">
              <h1>{checkLan('Aici copilul', 'Here the child')}<br />{checkLan('învață și se dezvoltă', 'learns and develops')}</h1>
              <p>{checkLan('Ambientul și metodele de instruire sunt orientate pe elev. Se ține cont de unicitatea copilului care trebuie să parcurgă cu succes cinci nivele de instruire: grădiniță, ABC’s, L 1-3, L 4-7, L 8-12.', 'The learning environment and methods are student-oriented. It takes into consideration the individuality of each child who must successfully complete five levels: Kindergarten, ABC’s, L 1-3, L 4-7, L 8-12.')}</p>
              <Link to='/curriculum' className="btn" style={{ background: '#bf3545', color: 'white' }}>Curriculum</Link>
            </div>
            <div className="col-md-1 col-sm-12"></div>
            <img src={Learning} alt="learning" className='col-md-4 w-100 col-sm-12' />
          </div>
        </section>
        <Levels />

        <div className='learning text container-fluid d-flex align-items-stretch' style={{
          background: '#f5f3e4',
          paddingBottom: 'calc(1vw + 1rem)',
          marginTop: 'calc(5vw + 5rem)',
          display: 'flex'
        }}>
          <div style={{ borderRadius: '10px', width: '100%', background: '#f6a091', padding: 'calc(.75vw + .75rem)', marginRight: 'calc(1vw + 1rem)' }}>
            <h1 style={{ fontSize: 'calc(.75vw + .75rem)' }}>{checkLan('Viziunea noastră', 'Our vision')}</h1>
            <p style={{ fontSize: 'calc(.5vw + .5rem)', lineHeight: 'calc(1vw + 1rem)' }}>{checkLan('Sprijinim părinții in educația copiilor lor, pregătindu-i să-și trăiască viața pentru gloria lui Dumnezeu.', 'We support and equip parents in the education of their children by preparing them to live their lives for the glory of God.')}</p>
          </div>
          <div style={{ borderRadius: '10px', width: '100%', background: '#f6a091', padding: 'calc(.75vw + .75rem)' }}>
            <h1 style={{ fontSize: 'calc(.75vw + .75rem)' }}>{checkLan('Misiunea noastră', 'Our mission')}</h1>
            <p style={{ fontSize: 'calc(.5vw + .5rem)', lineHeight: 'calc(1vw + 1rem)' }}>{checkLan('La Centrul Educațional Paideusis, oferim un cadru în care educația este centrată pe modelul Cristic, bazată pe Biblie, implicată din punct de vedere cultural și riguroasă din punct de vedere academic, respectând și prețuind unicitatea fiecărui copil.', 'At the Paideusis Educational Centre, we strive to provide an environment where education is Christ centered, based on the Bible, culturally relevant, and academically rigorous while respecting and appreciating the uniqueness of each child.')}</p>
          </div>
        </div>
      </div>

      <Faq />

      <section className="partners">
        <h1>{checkLan('Partenerii noștri', 'Our Partners')}</h1>
        <div className="container-fluid">
          <div className="row align-items-center">
            <a href='https://www.aceministries.com/' target='_blank' rel="noreferrer" className="col-2">
              <img src={AceLogo} alt="ace logo" />
            </a>
            <div className="col-1"></div>
            <a href='https://www.christian.education/' target='_blank' rel="noreferrer" className="col-4">
              <img src={CeeLogo} alt="cee-logo" />
            </a>
            <div className="col-1"></div>
            <a href='https://www.kingofkingsschool.org.uk/' target='_blank' rel="noreferrer" className="col-3">
              <img src={KingOfKingsLogo} alt='king of kings logo' />
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
