import React, { useContext, useEffect } from 'react'
import { GlobalContext } from '../GlobalState'
import './styles/Admissions.css'
import Desks from '../images/desks.png';
import Activities from '../images/activities.png'

const Admissions = () => {
  const { changePage, checkLan } = useContext(GlobalContext);

  useEffect(() => {
    changePage('admissions');
  }, [changePage]);

  return (
    <section id='admissions'>
      <section className="landing container-fluid">
        <div className="row align-items-center">
          <div className="col-md-4 col-sm-12 text">
            <h1>{checkLan('Înscrieri', 'Admissions')}</h1>
            <p className='font-weight-bold'>{checkLan('Înscrierile la Școala King of Kings din Anglia se realizează prin intermediul Centrului Educațional Paideusis. Înscrierea presupune participarea părinților împreună cu copilul la interviu, completarea cererii de înscriere, admiterea în urma interviului, semnarea contractului.', 'Enrollment at King of Kings School in England is made through Paideusis Educational Centre. The registration implies a parent-child interview, the completion of a registration application, the admission after the interview, and the signing of the contract.')}</p>
          </div>
          <div className="col-md-1 col-sm-12"></div>
          <div className="col-md-7 col-sm-12">
            <img src={Desks} alt="desks" className='w-100' />
          </div>
        </div>
      </section>

      <section className="admissions-info container-fluid">
        <div className="row align-items-center">
          <div className="col-md-5 col-sm-12">
            <img src={Activities} alt="activities" className='mx-auto' />
          </div>
          <div className="col-md-1 col-sm-12"></div>
          <div className="col-md-6 col-sm-12 admissions-text">
            <h1>{checkLan('Taxe de înscriere', 'Tuition Fees')}</h1>
            <div>
              <p>{checkLan('Perioada de înscriere: s-a încheiat.', 'Registration period: closed.')}</p>
              <p>{checkLan('Numărul locurilor este limitat: 12 – 15 elevi/clasă.', 'The number of places is limited: 12 - 15 students / class.')}</p>
              {/* <h3>{checkLan('Taxă de interviu', 'Interview fee')}:</h3>
              <p>50 RON</p> */}
              <h3>{checkLan('Taxă de înscriere la Școala Creștină „King of Kings”', 'Enrollment Fee to "King of Kings" Christian School')}:</h3>
              <p>35 Euro {checkLan('(o singură plată)', '(one payment)')}</p>
              {/* <h3>{checkLan('Taxă lunară la Centrul Internațional de Educație Paideusis', 'Monthly fee at the Paideusis International Education Centre')}:</h3>
              <p>650 RON</p> */}
              <h3>{checkLan('Manuale A.C.E. ', 'A.C.E. Workbooks')}:</h3>
              <p>
                <span>{checkLan('Grădiniță - aproximativ 345 Euro/ an', 'Kindergarten - approximately 345 Euro/ year')}</span>
                <br />
                <span>{checkLan('ABC’s - aproximativ 375 Euro/ an', 'ABC’s - approximately  375 Euro/ year')}</span>
                <br />
                <span>{checkLan('Nivel 1-7 - aproximativ 450 Euro/ an', 'Level 1-7 - approximately  450 Euro/ year')}</span>
                <br />
                <span>{checkLan('Nivel 8-12 - aproximativ 525 Euro/ an', 'Level 8-12 - approximately  525 Euro/ year')}</span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

export default Admissions
